<template>
  <div style="margin: 10px; width: calc(100vw - 85px)">
    <div>
      <el-switch
        v-model="showLabels"
        :active-text="$t('settings.showLabels')"
        inactive-text=""
      >
      </el-switch>
    </div>
    <el-col v-if="showLabels">
      <el-row>
        <el-col style="width: 90px"><p>{{ $t('settings.labelTextSize') }}</p></el-col>
        <el-col :span="2" style="padding:10px"><el-input v-model="labelTextSize" type="number"></el-input></el-col>
      </el-row>
    </el-col>
    <div style="margin-top: 20px">
      <el-switch
        v-model="showPOIsLabels"
        :active-text="$t('settings.showPOIsLabels')"
        inactive-text=""
      >
      </el-switch>
    </div>
    <div style="margin-top: 20px">
      <el-switch
        v-model="reducePoiSize"
        :active-text="$t('settings.reducePoiSize')"
        inactive-text=""
      >
      </el-switch>
    </div>
    <div style="margin-top: 20px">
      <el-switch
        v-model="showFullDate"
        :active-text="$t('settings.showFullDate')"
        inactive-text=""
      >
      </el-switch>
    </div>
    <div style="margin-top: 20px">
      <el-switch
        v-model="viewSpeedAlerts"
        :active-text="$t('settings.view_speed_alerts')"
        inactive-text=""
      >
      </el-switch>
    </div>
    <div style="margin-top: 20px">
      <el-switch
        v-model="viewEventsOnRoute"
        :active-text="$t('viewEventsOnRoute')"
        inactive-text=""
      >
      </el-switch>
    </div>
    <div style="margin-top: 20px">
      <el-switch
        v-model="viewIdlePoints"
        :active-text="$t('viewIdlePoints')"
        inactive-text=""
      >
      </el-switch>
    </div>
    <div style="margin-top: 20px">
      <el-switch
        v-model="viewPoiCircles"
        :active-text="$t('Show poi circles')"
        inactive-text=""
      >
      </el-switch>
    </div>
    <div style="margin-top: 20px">
      <el-switch
        v-model="poiAllowOverlap"
        :active-text="$t('Allow poi collisions')"
        inactive-text=""
      >
      </el-switch>
    </div>
    <h3 style="margin-top: 30px">{{ $t('settings.zone') }}</h3>
    <el-col>
      <el-row>
        <el-col style="width: 90px"><p>{{ $t('settings.labelTextSize') }}</p></el-col>
        <el-col :span="4" style="padding:10px"><el-input v-model="labelPOITextSize" type="number"></el-input></el-col>
      </el-row>
    </el-col>
  </div>
</template>

<script>
import * as lnglat from '../../../utils/lnglat'
import { mapGetters } from 'vuex'
import { languages, setLanguage } from '@/lang'
import { timezones } from '@/utils/consts'
import { traccar } from '@/api/traccar-api'
import { serverBus } from '@/main'
import { forgotPassword } from '@/api'
import { schedulerIsActive } from '@/utils/modules'
import store from '@/store'
import { pinmeapi } from '@/api/pinme'

export default {
  name: 'Settings',
  data() {
    return {
      radioValue: 'road',
      routeColor: 0,
      dirty: false,
      loading: false,
      languages: languages,
      timezones: timezones,
      passwordType: 'password',
      rules: {
        name: [
          { required: true, message: this.$t('profile.user_name_required'), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['vehicles3dEnabled', 'user']),
    forgotPass() {
      return forgotPassword()
    },
    labelColor: {
      get() { return this.$store.getters.labelColor },
      set(value) {
        this.changeSetting('labelColor', value)
      }
    },
    labelTextSize: {
      get() { return this.$store.getters.labelTextSize },
      set(value) {
        this.changeSetting('labelTextSize', parseInt(value))
      }
    },
    labelPOITextSize: {
      get() { return this.$store.getters.labelPOITextSize },
      set(value) {
        this.$store.commit('map/SET_LABEL_POI_TEXT_SIZE', parseInt(value))
      }
    },
    showFullDate: {
      get() { return this.$store.state.settings.showFullDate },
      set(value) {
        this.$store.commit('settings/SET_SHOW_FULLDATE', value)
      }
    },
    showLabels: {
      get() { return this.$store.state.settings.showLabels },
      set(value) {
        this.$store.dispatch('settings/setShowLabels', value)
      }
    },
    showPOIsLabels: {
      get() { return this.$store.state.settings.showPOIsLabels },
      set(value) {
        this.$store.dispatch('settings/setShowPOIsLabels', value)
      }
    },
    reducePoiSize: {
      get() { return this.$store.state.settings.reducePoiSize },
      set(value) {
        this.$store.dispatch('settings/setReducePoiSize', value)
      }
    },
    top() {
      if (('standalone' in window.navigator) && window.navigator.standalone) {
        return 'padding-top:100px;'
      }
      return 'padding-top:15px'
    },
    isMobile() { return lnglat.isMobile() },
    manageSchedules() {
      return schedulerIsActive(store.getters.user)
    },
    matchRoutes: {
      get() { return this.$store.state.settings.matchRoutes },
      set(value) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'matchRoutes',
          value: value
        })
      }
    },
    viewSpeedAlerts: {
      get() { return this.$store.getters.viewSpeedAlerts },
      set(value) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'viewSpeedAlerts',
          value: value
        })
      }
    },
    viewIdlePoints: {
      get() { return this.$store.getters.viewIdlePoints },
      set(value) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'viewIdlePoints',
          value: value
        })
      }
    },
    viewEventsOnRoute: {
      get() { return this.$store.getters.viewEventsOnRoute },
      set(value) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'viewEventsOnRoute',
          value: value
        })
      }
    },
    viewPoiCircles: {
      get() { return this.$store.state.map.viewPoiCircles },
      set(value) {
        this.$store.commit('map/CHANGE_SETTING', {
          key: 'viewPoiCircles',
          value: value
        })
      }
    },
    poiAllowOverlap: {
      get() { return this.$store.state.map.poiAllowOverlap },
      set(value) {
        this.$store.commit('map/CHANGE_SETTING', {
          key: 'poiAllowOverlap',
          value: value
        })
      }
    },
    icons3d: {
      get() { return this.vehicles3dEnabled },
      set(value) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'vehicles3d',
          value: value
        })
      }
    }
  },
  methods: {
    tabClicked(e) {
      serverBus.$emit('driversClicked', e)
    },
    changeSetting(key, value) {
      this.$store.dispatch('settings/changeSetting', { key, value })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    submit() {
      this.$refs.user.validate(valid => {
        if (valid) {
          this.loading = true
          traccar.updateUser(this.user.id, this.user
          ).then(({ data }) => {
            this.dirty = false
            setLanguage(data.attributes.lang)
            this.$message({
              message: this.$t('profile.user_updated'),
              type: 'success',
              duration: 5 * 1000
            })
          }).catch((e) => {
            this.$message({
              message: e,
              type: 'error',
              duration: 5 * 1000
            })
          }).finally(() => { this.loading = false })
        }
      })
    },
    async whatsappChanged(value) {
      this.dirty = !value
      if (value) {
        try {
          await this.$confirm(
            this.$t('Al activar esta opción recibirá mensajes de alarmas en su whatsapp, si deséa cancelar vuelva a esta pantalla y desactive la opcion. Desea continuar?'),
            this.$t('Aviso'), {
              confirmButtonText: this.$t('Continuar'),
              cancelButtonText: this.$t('Cancelar'),
              type: 'warning'
            })
          this.loading = true
          await pinmeapi.sendWhatsApp(this.user, 'optin')
          await traccar.updateUser(this.user.id, this.user)
          this.$message({ type: 'success', message: this.$t('Enviámos un mensaje de confirmación a su móvil.') })
        } catch (e) {
          this.user.attributes.whatsapp = false
          if (e.message) { this.$message({ type: 'error', message: e }) }
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/element-variables';
.el-avatar {
  color:$--color-text-regular;
  opacity: 0.95;
  font-weight: bold;
}
a:link {
  text-decoration: none;
}
.right-menu {
  position: absolute;
  right: 15px;
  top:15px;
  background: rgba(0, 0, 0, 0);
  z-index: 99;
}
$dark_gray:#889aa4;
.show-pwd {
  position: absolute;
  right: 10px;
  top: 1px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
.app-container {
    padding-left: 10px;
  }
</style>

